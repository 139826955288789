import React from 'react'
import { Color, Dropdown, MoreBar } from '@revolut/ui-kit'
import { ExclamationMarkOutline, Flag } from '@revolut/icons'

import { HR_SERVICE_DESK_LINK } from '@src/constants/externalLinks'
import { useCanViewFlagPerformance } from '../common'
import { EmployeeButtonProps } from '../types'

export const FlagPerformance = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const canView = useCanViewFlagPerformance({ data })

  if (!canView) {
    return null
  }

  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use="a"
        href={`${HR_SERVICE_DESK_LINK}/group/291/create/12710?customfield_13299=${
          window.location
        }&summary=${data?.full_name} ${encodeURIComponent(data?.job_title)}`}
        target="_blank"
        rel="noopener noreferrer"
        color={Color.RED}
        useIcon={Flag}
      >
        Flag performance
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      use="a"
      href={`${HR_SERVICE_DESK_LINK}/group/291/create/12710?customfield_13299=${
        window.location
      }&summary=${data?.full_name} ${encodeURIComponent(data?.job_title)}`}
      target="_blank"
      variant="negative"
      rel="noopener noreferrer"
      useIcon={ExclamationMarkOutline}
    >
      Flag performance
    </MoreBar.Action>
  )
}
