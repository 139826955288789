import React from 'react'
import { Button, Flex } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { CustomFieldsInterface, SectionOptions } from '@src/interfaces/customFields'
import {
  customFieldCreatedByColumn,
  customFieldCreatedDateColumn,
  customFieldGroups,
  customFieldInputTypeColumn,
  customFieldMandatoryColumn,
  customFieldNameColumn,
  customFieldPlaceholderColumn,
  customFieldSectionColumn,
  customFieldStatusColumn,
  customFieldSubSectionColumn,
  customFieldTooltipColumn,
} from '@src/constants/columns/customFields'
import { canAddCustomField } from '@src/store/auth/selectors'
import { customFieldsRequestTable } from '@src/api/customFields'
import { navigateTo } from '@src/actions/RouterActions'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useGlobalSettings } from '@src/api/settings'

interface CustomFieldsTableProps {
  sectionId: SectionOptions
}

const Row: RowInterface<CustomFieldsInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.CUSTOM_FIELDS.GENERAL, { id: data.id })),
  cells: [
    {
      ...customFieldNameColumn,
      width: 100,
    },
    {
      ...customFieldCreatedByColumn,
      width: 100,
    },
    {
      ...customFieldGroups,
      width: 100,
    },
    {
      ...customFieldSubSectionColumn,
      width: 100,
    },
    {
      ...customFieldInputTypeColumn,
      width: 100,
    },
    {
      ...customFieldMandatoryColumn,
      width: 100,
    },
    {
      ...customFieldPlaceholderColumn,
      width: 100,
    },
    {
      ...customFieldTooltipColumn,
      width: 100,
    },
    {
      ...customFieldCreatedDateColumn,
      width: 100,
    },
    {
      ...customFieldStatusColumn,
      width: 100,
    },
  ],
}

const CustomFieldsTable = ({ sectionId }: CustomFieldsTableProps) => {
  const showAddButton = useSelector(canAddCustomField)

  const filterBy = [
    {
      filters: [{ name: sectionId, id: sectionId }],
      columnName: 'section__id',
      nonResettable: true,
    },
  ]

  const table = useTable<CustomFieldsInterface>(
    { getItems: customFieldsRequestTable },
    filterBy,
  )

  const { settings } = useGlobalSettings()

  return (
    <TableWrapper>
      {showAddButton && (
        <Flex mb="s-16">
          <Button
            onClick={() => {
              navigateTo(
                `${pathToUrl(
                  ROUTES.FORMS.CUSTOM_FIELDS.GENERAL,
                )}?section_id=${sectionId}`,
              )
            }}
            mr="s-16"
            useIcon={Plus}
            size="sm"
            variant="secondary"
          >
            Add custom field
          </Button>
        </Flex>
      )}
      <AdjustableTable
        name={TableNames.CustomFields}
        useWindowScroll
        row={Row}
        {...table}
        noDataMessage="Custom fields will appear here."
        hiddenCells={{
          [customFieldSectionColumn.idPoint]: !settings.benefits_enabled,
          [customFieldSubSectionColumn.idPoint]:
            sectionId !== SectionOptions.EmployeeProfile,
        }}
      />
    </TableWrapper>
  )
}

export default CustomFieldsTable
