import React, { useEffect, useState } from 'react'
import * as pdfJsLib from 'pdfjs-dist'

type UseScaleParams = {
  initValue?: number
  max?: number
  min?: number
  step?: number
}
export type PdfPreviewScale = {
  value: number
  reset: () => void
  up: () => void
  upDisabled: boolean
  down: () => void
  downDisabled: boolean
}
export const useScale = ({
  initValue = 1,
  max = 3,
  min = 0.2,
  step = 0.1,
}: UseScaleParams): PdfPreviewScale => {
  const [value, setValue] = useState(initValue)

  const reset = () => setValue(initValue)

  const upDisabled = value >= max
  const up = () => {
    if (!upDisabled) {
      setValue(value + step)
    }
  }

  const downDisabled = value <= min
  const down = () => {
    if (!downDisabled) {
      setValue(value - step)
    }
  }

  return {
    value,
    reset,
    up,
    upDisabled,
    down,
    downDisabled,
  }
}

type UsePaginationParams = {
  initValue?: number
}
export type PdfPreviewPagination = {
  current: number
  prev: () => void
  prevDisabled: boolean
  next: () => void
  nextDisabled: boolean
  total: number | undefined
  setTotal: (n: number) => void
}
export const usePagination = ({
  initValue = 1,
}: UsePaginationParams): PdfPreviewPagination => {
  const [total, setTotal] = useState<number>()
  const [current, setCurrent] = useState(initValue)

  const nextDisabled = !total || current === total
  const next = () => {
    if (!nextDisabled) {
      setCurrent(current + 1)
    }
  }

  const prevDisabled = current === 1
  const prev = () => {
    if (!prevDisabled) {
      setCurrent(current - 1)
    }
  }

  return {
    current,
    prev,
    prevDisabled,
    next,
    nextDisabled,
    total,
    setTotal,
  }
}

type UsePdfPreviewParams = {
  url: string | undefined
  canvasRef: React.RefObject<HTMLCanvasElement>
  width: number | undefined
  scale?: number
  page?: number
  onMount?: (pdfFile: pdfJsLib.PDFDocumentProxy) => void
  onPageRendered?: (renderCtx: {
    canvasContext: Object
    viewport: pdfJsLib.PageViewport
  }) => void
  adjustByParentWidth?: boolean
}
export type PdfPreviewRenderCtx = {
  isLoading: boolean
  isMounted: boolean
}
export const useRenderPdf = ({
  url,
  canvasRef,
  width,
  scale = 1,
  page = 1,
  onMount,
  onPageRendered,
  adjustByParentWidth,
}: UsePdfPreviewParams) => {
  const [isMounted, setIsMounted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!url || !width) {
      return
    }
    setIsLoading(true)

    pdfJsLib
      .getDocument(url)
      .promise.then(pdfFile => {
        const canvas = canvasRef.current

        if (canvas) {
          pdfFile.getPage(page).then(p => {
            const viewport = p.getViewport({
              scale: adjustByParentWidth
                ? width / p.getViewport({ scale: 1 / scale }).width
                : scale,
            })
            canvas.width = viewport.width
            canvas.height = viewport.height

            const renderCtx = {
              canvasContext: canvas.getContext('2d') as Object,
              viewport,
            }
            p.render(renderCtx).promise.then(res => {
              onPageRendered?.(renderCtx)
              return res
            })
          })
        }
        if (!isMounted) {
          setIsMounted(true)
          onMount?.(pdfFile)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [url, width, scale, page])

  return { isLoading, isMounted }
}
