import React from 'react'
import { Cell, Flex, H6, Subheader, VStack, Text, Token, Box } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { UploadTemplateFile } from '@src/apps/People/Documents/Template/Edit/common/UploadTemplateFile'

export const TabUploadFile = () => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  if (values.file_name) {
    // TODO: RHR-4532 pdf file preview
    return (
      <Cell p="s-64">
        <Flex flexDirection="column" alignItems="center" width="100%">
          <H6>Uploaded: {values.file_name}</H6>
        </Flex>
      </Cell>
    )
  }
  return (
    <VStack space="s-32">
      <Box>
        <Subheader>
          <Subheader.Title>Upload the template for your eSignature</Subheader.Title>
        </Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          This is the base file to be used to generate the signatures on
        </Text>
      </Box>
      <UploadTemplateFile />
    </VStack>
  )
}
