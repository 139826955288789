import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'
import { Badge, Box, Token } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'

import { CommonTabProps } from '../common'
import { TabBasics } from '../common/TabBasics'
import { TabUploadFile } from './TabUploadFile'

const tabsOrder = [
  {
    title: 'Basics',
    key: 'basics' as const,
    path: ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.BASICS,
    component: (props: CommonTabProps) => (
      <TabBasics templateTypeId="esignature" {...props} />
    ),
  },
  {
    title: 'Upload / Template',
    key: 'template' as const,
    path: ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.FILE,
    component: TabUploadFile,
  },
]

const useTabs = (values: DocumentsTemplatesInterface) =>
  useMemo(() => {
    const isCreated = !!values.id

    const getTabProps = (path: string) => {
      if (path === ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.BASICS) {
        return {
          disabled: false,
          completed: isCreated,
        }
      }
      if (path === ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.FILE) {
        return {
          disabled: !isCreated,
          completed: false,
        }
      }
      return { disabled: false, completed: false }
    }

    return tabsOrder.map((tab, idx) => {
      const { disabled, completed } = getTabProps(tab.path)

      return {
        ...tab,
        disabled,
        url: isCreated ? pathToUrl(tab.path, { id: values.id }) : undefined,
        preTitle: (
          <Box mr="s-8">
            <Badge size={16} bg={completed ? Token.color.green : Token.color.foreground}>
              {idx + 1}
            </Badge>
          </Box>
        ),
      }
    })
  }, [values.id])

export const EditDocumentsESignatureTemplate = connect(() => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()
  const tabs = useTabs(values)

  return (
    <>
      <PageHeader
        title={values.id ? values.name : 'New documents template'}
        subtitle={values.id ? 'New documents template' : undefined}
        backUrl={ROUTES.APPS.DOCUMENTS.TEMPLATES.LIST}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <PageBody>
        <TabBarNavigation tabs={tabs} mb="s-16" />
        <Switch>
          {tabs.map((tab, idx) => (
            <Route exact key={tab.key} path={tab.path}>
              <tab.component nextTabPath={tabs[idx + 1]?.path} />
            </Route>
          ))}
        </Switch>
      </PageBody>
    </>
  )
})
