import React, { useEffect, useRef, useState } from 'react'
import { Box, Subheader, VStack, Text, Token } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { UploadTemplateFile } from '@src/apps/People/Documents/Template/Edit/common/UploadTemplateFile'
import { downloadTemplateFileAsBlob } from '@src/api/documentsTemplates'
import { PdfPreview } from '@src/features/PdfPreview'

export const TabUploadFile = () => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  const [fileUrl, setFileUrl] = useState<string>()

  useEffect(() => {
    if (!values.file_name) {
      return
    }
    downloadTemplateFileAsBlob(values.id).then(res => setFileUrl(res))
  }, [values.file_name])

  const containerRef = useRef<HTMLDivElement>(null)

  if (values.file_name) {
    return (
      <Box ref={containerRef}>
        <PdfPreview
          adjustByParentWidth
          fileUrl={fileUrl}
          containerRef={containerRef}
          toolbarStickyOffset={60}
        />
      </Box>
    )
  }
  return (
    <VStack space="s-32">
      <Box>
        <Subheader>
          <Subheader.Title>
            Upload an example of the document you want to request
          </Subheader.Title>
        </Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          Please attach a sample document pdf file to go with the request instructions
        </Text>
      </Box>
      <UploadTemplateFile />
    </VStack>
  )
}
