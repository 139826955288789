import { HStack, InputGroup, StatusPopup, VStack, useStatusPopup } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { GoalsInterface } from '@src/interfaces/goals'
import { selectUser } from '@src/store/auth/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CellWithItem } from '../common/CellWithItem'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { useHistory, useLocation } from 'react-router-dom'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { TargetsWidget } from './Widgets/Targets/TargetsWidget'
import { RoadmapsWidget } from './Widgets/Roadmaps/RoadmapsWidget'
import { DashboardsWidget } from './Widgets/Dashboards/DashboardsWidget'
import { OrgUnitSelector } from './OrgUnitSelector'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGoalParentFilter } from '@src/features/Goals/useGoalFilters'
import { ParentGoalField } from '../common/ParentGoalField'

export const GoalForm = ({
  enforceParentKpi,
  defaultReviewCycle,
}: {
  enforceParentKpi: boolean
  defaultReviewCycle?: ReviewCyclesInterface
}) => {
  const location = useLocation<{ dashboard?: AnalyticsDashboardInterface }>()
  const history = useHistory()
  const user = useSelector(selectUser) as unknown as EmployeeOptionInterface
  const { values } = useLapeContext<GoalsInterface>()
  const [parentKpiRequired, setParentKpiRequired] = useState(enforceParentKpi)
  const parentFilter = useGoalParentFilter(values.content_type?.model)
  const statusPopup = useStatusPopup()

  useEffect(() => {
    if (
      !parentKpiRequired &&
      values.kpis?.length &&
      values.kpis[0].is_inherited === true
    ) {
      setParentKpiRequired(true)
    } else if (parentKpiRequired !== enforceParentKpi) {
      setParentKpiRequired(enforceParentKpi)
    }
  }, [values.kpis])

  useEffect(() => {
    if (!values.owner) {
      values.owner = user
    }
    if (!values.description) {
      values.description = `Summary of what this goal measures (context):
How will it be measured (formula & unit):
The business goal this goal supports:
Any risks linked with achieving this goal:
`
    }
  }, [])

  useEffect(() => {
    const onAfterAdded = () => {
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Success</StatusPopup.Title>
          <StatusPopup.Description>
            Dashboard successfully added to the goal
          </StatusPopup.Description>
        </StatusPopup>,
      )
      history.replace(location.pathname, { ...location.state, dashboard: undefined })
    }
    if (
      location.state?.dashboard &&
      values.dashboards &&
      !values.dashboards.find(d => d.id === location.state.dashboard?.id)
    ) {
      values.dashboards = [...values.dashboards, location.state.dashboard]
      delete location.state.dashboard
      onAfterAdded()
    } else if (
      location.state?.dashboard &&
      (!values.dashboards || values.dashboards?.length === 0)
    ) {
      values.dashboards = [location.state.dashboard]
      delete location.state.dashboard
      onAfterAdded()
    }
  }, [location.state?.dashboard])

  return (
    <VStack space="s-24" mb="s-24">
      <CellWithItem
        icon="Target"
        title="Define your goal"
        description="What is the outcome you want to achieve?"
      >
        <InputGroup>
          <LapeNewInput name="name" label="Goal name" required />
          <HStack gap="s-16">
            <LapeRadioSelectInput
              selector={selectorKeys.employee}
              name="owner"
              label="Owner"
              required
            />
            <OrgUnitSelector />
          </HStack>
          {values.is_company || !parentFilter ? null : (
            <ParentGoalField
              contentType={values.content_type?.model}
              required={parentKpiRequired}
              value={values.parent}
              onChange={parent => {
                values.parent = parent
              }}
            />
          )}
          <LapeNewTextArea
            name="description"
            label="Description and definition of done"
            required
            rows={3}
          />
        </InputGroup>
      </CellWithItem>
      <TargetsWidget initialCycle={defaultReviewCycle} />
      <RoadmapsWidget initialCycle={defaultReviewCycle} />
      {values.content_type?.model !== 'employees' ? <DashboardsWidget /> : null}
    </VStack>
  )
}
