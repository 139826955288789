import React from 'react'

import { Flex } from '@revolut/ui-kit'
import { TeamInterface } from '@src/interfaces/teams'
import { TimeOffCalendar } from '@src/features/TimeOffCalendarTable/TimeOffCalendar'
import {
  useCalendarFilters,
  useTableCalendarSwitcher,
  useWeekMonthSwitcher,
} from '@src/features/TimeOffCalendarTable/hooks'
import { getTeamTimeOffCalendar } from '@src/api/timeOff'
import { TableNames } from '@src/constants/table'
import { TimeOffTable } from './TimeOffTable'
import { TableCalendarTabs } from '@src/features/TimeOffCalendarTable/constants'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'

enum WeekMonthTabs {
  Week = 'Week',
  Month = 'Month',
}

interface TimeOffProps {
  data: TeamInterface
  navigation?: React.ReactElement
}
export const TimeOff = ({ data, navigation }: TimeOffProps) => {
  const { isMonthView, clearCalendarFilters } = useCalendarFilters()
  const weekMonthSwitcher = useWeekMonthSwitcher(isMonthView)

  const isNewLayout = useIsNewOrgLayout()

  const user = useSelector(selectUser)
  const employeeTeamView = user.team.id === data.id

  const tableCalendarSwitcher = useTableCalendarSwitcher(tab => {
    if (tab === TableCalendarTabs.Table) {
      clearCalendarFilters()
    }
  })

  const initialFilters = employeeTeamView
    ? [
        {
          columnName: 'approval_status',
          filters: [{ id: 'approved', name: 'approved' }],
          nonResettable: true,
        },
      ]
    : []

  return (
    <TableWrapper>
      {isNewLayout && navigation}

      <Flex mb="s-24" gap="s-8">
        {tableCalendarSwitcher.tabBar}
        {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar
          ? weekMonthSwitcher.tabBar
          : null}
      </Flex>

      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Table && (
        <TimeOffTable
          data={data}
          employeeTeamView={employeeTeamView}
          initialFilters={initialFilters}
        />
      )}
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar && (
        <TimeOffCalendar
          tableName={TableNames.TeamTimeOffCalendar}
          data={data}
          weekMonthTab={weekMonthSwitcher.currentTab || WeekMonthTabs.Week}
          getItems={getTeamTimeOffCalendar(data.id)}
          hidePolicy={employeeTeamView}
          initialFilters={initialFilters}
        />
      )}
    </TableWrapper>
  )
}
