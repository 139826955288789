import React from 'react'
import CustomFieldsTable from '@src/pages/People/PeopleSubTabs/CustomFields/CustomFieldsTable'
import { Box } from '@revolut/ui-kit'
import { SectionOptions } from '@src/interfaces/customFields'

export const CustomFields = () => {
  return (
    <Box mt="s-32">
      <CustomFieldsTable sectionId={SectionOptions.Benefits} />
    </Box>
  )
}
