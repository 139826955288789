import React from 'react'
import { ROUTES } from '@src/constants/routes'
import TeamTalent from '@src/pages/Forms/CommonTalentTab/Talent'
import TeamTalentPerformance from '@src/pages/Forms/CommonTalentTab/TalentPerformance'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { TalentType } from '@src/interfaces/talent/talent'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Flex } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { TeamInterface } from '@src/interfaces/teams'
import { PermissionTypes, EntityPermissions } from '@src/store/auth/types'
import { TimeOff } from '../TimeOff/TimeOff'
import TeamSchedule from '@src/pages/Forms/TeamForm/Schedule/Schedule'
import {
  useGlobalSettings,
  useGetTimeOffSettings,
  useGetRequisitionSettings,
  useGetOrganisationSettings,
} from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import TeamRequisitions from '@src/pages/Forms/TeamForm/Requisitions/Requisitions'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'
import TeamBudget from '@src/pages/Forms/TeamForm/Budget/Budget'

const Talent = ({ data }: { data: TeamInterface }) => {
  const params = useParams<{ id: string }>()
  const { data: timeOffSettings } = useGetTimeOffSettings()
  const {
    settings: { attendance_enabled, compensation_enabled },
  } = useGlobalSettings()
  const user = useSelector(selectUser)
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()

  const isNewLayout = useIsNewOrgLayout()

  const budgetManagementEnabled = !!organisationSettings?.enable_budget_management
  const timeOffEnabled = !!timeOffSettings?.enabled
  const canViewTimeOffByEntityPermission = data?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOff,
  )
  const canViewTimeOffAsTeamMember = user.team?.id === data.id
  const canViewTimeOff = canViewTimeOffByEntityPermission || canViewTimeOffAsTeamMember
  const canViewCompensation =
    compensation_enabled &&
    budgetManagementEnabled &&
    data?.field_options?.permissions?.includes(EntityPermissions.ViewCompensationReviews)

  const subTabs = [
    {
      path: ROUTES.FORMS.TEAM.TALENT.GENERAL,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.GENERAL, params),
      title: 'General',
      component: TeamTalent,
      canView: true,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.TEAM.TALENT.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.REQUISITIONS, params),
      component: TeamRequisitions,
      quickSummary: <QuickSummaryCount count={data.requisition_remaining_headcount} />,
      canView: requisitionSettings?.enabled && isNewLayout,
    },
    {
      path: ROUTES.FORMS.TEAM.TALENT.PERFORMANCE,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.PERFORMANCE, params),
      title: 'Performance',
      component: TeamTalentPerformance,
      canView: data.field_options.permissions?.includes(
        PermissionTypes.ViewEmployeePerformance,
      ),
    },
    {
      path: ROUTES.FORMS.TEAM.TALENT.TIME_OFF,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.TIME_OFF, params),
      title: 'Time Off',
      component: TimeOff,
      canView: timeOffEnabled && canViewTimeOff,
    },
    {
      path: ROUTES.FORMS.TEAM.TALENT.SCHEDULE,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.SCHEDULE, params),
      title: 'Schedule',
      component: TeamSchedule,
      canView: !!data?.id && attendance_enabled,
    },
    {
      path: ROUTES.FORMS.TEAM.TALENT.COMPENSATION,
      to: pathToUrl(ROUTES.FORMS.TEAM.TALENT.COMPENSATION, params),
      title: 'Compensation',
      component: TeamBudget,
      canView: canViewCompensation,
    },
  ]

  const filteredTabs = subTabs.filter(tab => tab.canView)

  const navigation = (
    <Flex alignItems="flex-start" mb="s-24">
      <TabBarNavigation isSubtab tabs={filteredTabs} />
    </Flex>
  )

  return (
    <Flex flexDirection="column" minHeight={0}>
      {filteredTabs.length > 1 && !isNewLayout && navigation}
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component
              data={data}
              talentType={TalentType.Team}
              navigation={isNewLayout ? navigation : undefined}
            />
          </Route>
        ))}
        {filteredTabs?.[0]?.path && <Redirect to={filteredTabs?.[0]?.path} />}
      </Switch>
    </Flex>
  )
}

export default Talent
