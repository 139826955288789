import React from 'react'
import { useTable } from '@components/Table/hooks'
import { getTeamIssues, useTeamIssuesCounts } from '@src/api/issues'
import { TeamInterface } from '@src/interfaces/teams'
import { IssueInterface, IssueType } from '@src/interfaces/issues'
import { IssuesTable } from '@src/features/IssuesTable/IssuesTable'
import { TableNames } from '@src/constants/table'
import { AuditStatuses } from '@src/interfaces/audit'
import { useParams, Route } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { TableWidget } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'

const categoryFilterMap: Record<string, IssueType> = {
  user: 'user_issue',
  product: 'product_audit',
  technical: 'technical_bug',
}

const TeamIssuesTable = ({ data, type }: { data: TeamInterface; type: IssueType }) => {
  const initialFilterBy = [
    {
      filters: [
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
      ],
      columnName: 'status',
      nonResettable: true,
    },
    {
      filters: [{ id: type, name: type }],
      columnName: 'category',
      nonResettable: true,
    },
  ]

  const table = useTable<IssueInterface>(
    {
      getItems: getTeamIssues(data?.id),
    },
    initialFilterBy,
  )

  const { data: counts } = useTeamIssuesCounts(data?.id, initialFilterBy)

  return <IssuesTable table={table} name={TableNames.DepartmentIssues} counts={counts} />
}

export const Issues = ({ data }: { data: TeamInterface }) => {
  const params = useParams<{ subtab: string }>()

  const type = categoryFilterMap[params.subtab]

  const tabs = [
    {
      title: 'User Issue',
      path: ROUTES.FORMS.TEAM.ISSUES.USER,
      to: pathToUrl(ROUTES.FORMS.TEAM.ISSUES.USER, params),
    },
    {
      title: 'Product Audit',
      path: ROUTES.FORMS.TEAM.ISSUES.PRODUCT,
      to: pathToUrl(ROUTES.FORMS.TEAM.ISSUES.PRODUCT, params),
    },
    {
      title: 'Technical Bug',
      path: ROUTES.FORMS.TEAM.ISSUES.TECHNICAL,
      to: pathToUrl(ROUTES.FORMS.TEAM.ISSUES.TECHNICAL, params),
    },
  ]

  return (
    <TableWidget>
      <TabBarNavigation isSubtab tabs={tabs} mb="s-24" />
      {tabs.map(tab => (
        <Route exact path={tab.path} key={tab.path}>
          <TeamIssuesTable data={data} type={type} />
        </Route>
      ))}
    </TableWidget>
  )
}
