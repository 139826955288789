import { HStack, Token, Text, Icon } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import React from 'react'
import { IconName } from '@revolut/ui-kit/types/dist/components/Icon/types'

interface PageHeaderLabelProps {
  name: string
  icon: IconName
  url: string
}

export const Label = ({ name, icon, url }: PageHeaderLabelProps) => {
  return (
    <HStack gap="s-4" align="center">
      <Icon name={icon} color={Token.color.greyTone50} size={15} />
      <Text
        use={InternalLink}
        to={url}
        target="_blank"
        color={Token.color.greyTone50}
        variant="caption"
        textDecoration="none"
      >
        {name}
      </Text>
    </HStack>
  )
}
