import { Bell } from '@revolut/icons'
import { IconButton, Header } from '@revolut/ui-kit'
import React, { useState, useRef } from 'react'
import { MainHeaderButton } from '@src/features/MainHeader/MainHeaderButton'
import { useGetUnreadSystemNotifications } from '@src/api/systemNotifications'
import SideBar from '@components/SideBar/SideBar'
import { NotificationsList } from '@src/features/Notifications/NotificationsList'
import { useGetCommunicationSettings } from '@src/api/settings'
import { SystemNotificationInterface } from '@src/interfaces/systemNotifications'
import { SideBarHeaderTitle } from '@components/SideBar/SideBarHeaderTitle'
import { NotificationsIcon } from '@src/features/Notifications/NotificationsIcon'

const Notifications = () => {
  const { data: settings } = useGetCommunicationSettings()
  const { data: unread } = useGetUnreadSystemNotifications()
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [notification, setNotification] = useState<SystemNotificationInterface>()

  if (!settings?.enable_system_notifications) {
    return null
  }

  return (
    <>
      <MainHeaderButton>
        <IconButton
          useIcon={Bell}
          aria-label="Notifications"
          size={24}
          color="grey-tone-50"
          onClick={() => setOpen(true)}
        >
          {unread?.count ? <IconButton.Badge>{unread.count}</IconButton.Badge> : null}
        </IconButton>
      </MainHeaderButton>
      <SideBar
        title="Notifications"
        isOpen={open}
        onClose={() => {
          notification ? setNotification(undefined) : setOpen(false)
        }}
        sideProps={{ scrollRef: ref }}
        customHeader={
          notification ? (
            <Header variant="item" pb={0}>
              <Header.BackButton aria-label="Back" />
              <Header.Avatar>
                <NotificationsIcon name={notification.category} />
              </Header.Avatar>
              <SideBarHeaderTitle>{notification.title}</SideBarHeaderTitle>
            </Header>
          ) : undefined
        }
      >
        <NotificationsList
          scrollRef={ref}
          onSetNotification={data => setNotification(data)}
          selectedNotification={notification}
        />
      </SideBar>
    </>
  )
}

export default Notifications
