import React from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { FilterByInterface } from '@src/interfaces/data'
import { IdAndName } from '@src/interfaces'
import { LOCAL_STORAGE } from '@src/constants/api'
import { HStack, IconButton, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Tooltip from '@components/Tooltip/Tooltip'

type Props = {
  candidateId?: number
}

export const CandidateSwitcher = ({ candidateId }: Props) => {
  const [savedCandidatesContext] = useLocalStorage<{
    filterBy: FilterByInterface[]
    candidates: IdAndName[]
  }>(LOCAL_STORAGE.CANDIDATES_TABLE_CONTEXT, { filterBy: [], candidates: [] })

  const currentCandidateIdx = savedCandidatesContext.candidates.findIndex(
    candidate => candidate.id === candidateId,
  )

  if (!candidateId || currentCandidateIdx === -1) {
    return null
  }

  const nextCandidate = savedCandidatesContext.candidates[currentCandidateIdx + 1]
  const prevCandidate = savedCandidatesContext.candidates[currentCandidateIdx - 1]

  return (
    <HStack gap="s-16">
      <Tooltip placement="top" text={prevCandidate?.name} hide={!prevCandidate}>
        <IconButton
          useIcon="ChevronLeft"
          color={!prevCandidate ? Token.color.greyTone20 : undefined}
          disabled={!prevCandidate}
          onClick={() => {
            if (prevCandidate) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: prevCandidate.id }),
              )
            }
          }}
        />
      </Tooltip>
      <Tooltip placement="top" text={nextCandidate?.name} hide={!nextCandidate}>
        <IconButton
          useIcon="ChevronRight"
          disabled={!nextCandidate}
          color={!nextCandidate ? Token.color.greyTone20 : undefined}
          onClick={() => {
            if (nextCandidate) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: nextCandidate.id }),
              )
            }
          }}
        />
      </Tooltip>
    </HStack>
  )
}
