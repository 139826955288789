import React from 'react'
import { ColumnInterface, CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { IssueInterface } from '@src/interfaces/issues'
import { A } from '@components/CommonSC/General'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { Token } from '@revolut/ui-kit'
import pluralize from 'pluralize'

export const getSeverityColor = (data: IssueInterface) => {
  switch (data.severity.id) {
    case 'minor':
      return Token.color.greyTone50
    case 'medium':
      return Token.color.yellow
    case 'major':
      return Token.color.warning
    case 'blocker':
    case 'critical':
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const issueLinkColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'issue_link',
  dataPoint: 'issue_link',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => (
    <A href={data.issue_link} target="_blank">
      {data.issue_id}
    </A>
  ),
  selectorsKey: selectorKeys.none,
  title: 'Issue',
}

export const issueSummaryColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Summary',
}

export const issueSeverityColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'severity.id',
  dataPoint: 'severity.name',
  sortKey: 'severity',
  filterKey: 'severity',
  selectorsKey: selectorKeys.issue_severity,
  title: 'Severity',
  colors: getSeverityColor,
}

export const issueAssigneeColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'assignee.id',
  dataPoint: 'assignee.name',
  sortKey: 'assignee__full_name',
  filterKey: 'assignee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Assignee',
  insert: ({ data }) => <UserWithAvatar {...data.assignee} />,
}

export const issueOpenDateColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Open date',
}

export const issueDueDateColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.date,
  idPoint: 'due_date',
  dataPoint: 'due_date',
  sortKey: 'due_date',
  filterKey: 'due_date',
  selectorsKey: selectorKeys.none,
  title: 'Due date',
}

export const issueDaysLeftColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'days_left',
  dataPoint: 'days_left',
  sortKey: 'days_left',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Days left / Overdue',
  insert: ({ data }) => {
    if (!data.days_left) {
      return '-'
    }
    if (data.days_left < 0) {
      return 'Overdue'
    }
    return pluralize('day', data.days_left, true)
  },
}
