import React from 'react'
import { format } from 'date-fns'

import { Box, chain, Flex, Text, Token } from '@revolut/ui-kit'
import {
  EmployeeTimeOffRequestCalendar,
  EmployeeTimeOffRequestsCalendarInterface,
  PublicHolidayCalendarItem,
} from '@src/interfaces/timeOff'
import { newTabTo, pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  approvalStatusIdToIconColor,
  defaultBoxProps,
  getCellBoxProps,
  getRequestDayInfo,
  getRequestDayTextPeriod,
  getTimeOffCalendarInfoByDay,
  requestToIcon,
} from './helpers'
import {
  MONTH_CELL_HEIGHT,
  BORDER_WIDTH,
  MONTH_CELL_WIDTH,
  TooltipContainer,
} from './constants'

type MonthCellContentProps = {
  request: EmployeeTimeOffRequestCalendar | undefined
  publicHoliday: PublicHolidayCalendarItem | undefined
  isNonWorking: boolean
  canSeePolicy: boolean
  nonWorkingBody?: React.ReactNode
}
const MonthCellContent = ({
  request,
  isNonWorking,
  publicHoliday,
  canSeePolicy,
  nonWorkingBody,
}: MonthCellContentProps) => {
  if (isNonWorking && nonWorkingBody) {
    return <>{nonWorkingBody}</>
  }
  const boxProps = getCellBoxProps({
    request,
    isNonWorking,
    publicHoliday,
    canSeePolicy,
  })
  const nonWorking = isNonWorking || !!publicHoliday
  const requestDayInfo = getRequestDayInfo(request)
  const hasApprovalStatus = !!request?.approval_status.name

  const cardIcon = nonWorking ? (
    <Tooltip
      placement="bottom"
      body={
        publicHoliday ? (
          <TooltipContainer>
            <Text whiteSpace="pre">{publicHoliday.name}</Text>
          </TooltipContainer>
        ) : null
      }
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        width={MONTH_CELL_WIDTH - BORDER_WIDTH * 2}
        height={MONTH_CELL_HEIGHT - BORDER_WIDTH * 2}
        aria-label={publicHoliday ? 'Bank holiday' : 'Non working'}
      >
        {requestToIcon({
          isNonWorking,
          publicHoliday,
          canSeePolicy,
        })}
      </Flex>
    </Tooltip>
  ) : (
    <Tooltip
      placement="bottom"
      body={
        request ? (
          <TooltipContainer>
            {chain(
              <Text whiteSpace="pre" fontWeight="600">
                {canSeePolicy ? request?.category.name : 'Off'}
              </Text>,
              <Text whiteSpace="pre" color={Token.color.greyTone50}>
                {getRequestDayTextPeriod(request)}
              </Text>,
              hasApprovalStatus && (
                <Text
                  whiteSpace="pre"
                  color={approvalStatusIdToIconColor(request?.approval_status.id)}
                >
                  {request?.approval_status.name}
                </Text>
              ),
            )}
          </TooltipContainer>
        ) : null
      }
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        width={30}
        height={22}
        aria-label={`${
          canSeePolicy ? request?.category.name : 'Off'
        } · ${getRequestDayTextPeriod(request)}${
          hasApprovalStatus ? ` · ${request?.approval_status.name}` : ''
        }`}
      >
        {requestToIcon({ request, canSeePolicy })}
      </Flex>
    </Tooltip>
  )

  if (nonWorking || requestDayInfo?.isAllDay) {
    return (
      <Flex flex="1 0" {...boxProps}>
        {cardIcon}
      </Flex>
    )
  }
  if (requestDayInfo?.isMorning) {
    return (
      <>
        <Flex flex="1 0" {...boxProps} borderRadius="10px 10px 0 0">
          {cardIcon}
        </Flex>
        <Flex flex="1 0" {...defaultBoxProps} borderRadius="0 0 10px 10px" />
      </>
    )
  }
  if (requestDayInfo?.isAfternoon) {
    return (
      <>
        <Flex flex="1 0" {...defaultBoxProps} borderRadius="10px 10px 0 0" />
        <Flex flex="1 0" {...boxProps} borderRadius="0 0 10px 10px">
          {cardIcon}
        </Flex>
      </>
    )
  }
  return <Box {...boxProps} height="s-48" />
}

type Props = {
  day: Date
  requestsCalendar: EmployeeTimeOffRequestsCalendarInterface
  canSeePolicy?: boolean
  emptyRequestBody?: React.ReactNode
  nonWorkingBody?: React.ReactNode
}
export const TimeOffTableMonthCell = ({
  day,
  requestsCalendar,
  canSeePolicy,
  emptyRequestBody,
  nonWorkingBody,
}: Props) => {
  const { request, isNonWorking, publicHoliday } = getTimeOffCalendarInfoByDay(
    requestsCalendar,
    day,
  )
  const nonWorking = isNonWorking || !!publicHoliday
  const isEmpty = !request && !nonWorking
  const isHoverable = !!request?.id || !!publicHoliday

  if (isEmpty && emptyRequestBody) {
    return <>{emptyRequestBody}</>
  }

  return (
    <Flex
      height={MONTH_CELL_HEIGHT}
      width={MONTH_CELL_WIDTH}
      flexDirection="column"
      style={isHoverable ? { cursor: 'pointer' } : undefined}
      onClick={() => {
        if (request?.id) {
          newTabTo(
            pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
              id: request.id,
              employeeId: requestsCalendar.employee.id,
            }),
          )
        }
      }}
      aria-label={format(day, 'yyyy-MM-dd')}
    >
      <MonthCellContent
        request={request}
        isNonWorking={isNonWorking}
        publicHoliday={publicHoliday}
        canSeePolicy={!!canSeePolicy}
        nonWorkingBody={nonWorkingBody}
      />
    </Flex>
  )
}
