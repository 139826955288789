import React from 'react'
import { Box, Cell, VStack, Flex, Text, BREAKPOINTS } from '@revolut/ui-kit'

interface PageHeaderTitleProps {
  title: React.ReactNode
  labels?: React.ReactNode
  actions?: React.ReactNode
  side?: React.ReactNode
}

export const PageHeaderTitle = ({
  title,
  labels,
  actions,
  side,
}: PageHeaderTitleProps) => {
  return (
    <Box mt="s-8" maxWidth={{ all: '100%', lg: BREAKPOINTS.xl }}>
      <Cell pt="s-24" px="s-16" pb="s-16" width="100%">
        <VStack gap="s-16" overflow="hidden" width="100%">
          <Flex flex={1} alignItems="center" gap="s-16" width="100%">
            <VStack gap="s-4" width="100%">
              <Flex justifyContent="space-between" alignItems="flex-start" width="100%">
                <Text variant="h1" whiteSpace="pre-wrap">
                  {title}
                </Text>
                <Box>{side}</Box>
              </Flex>

              <Flex flexWrap="wrap" gap={['s-2', 's-8']}>
                {labels}
              </Flex>
            </VStack>
          </Flex>
          {actions}
        </VStack>
      </Cell>
    </Box>
  )
}
