import React, { useRef, useState } from 'react'
import * as pdfJsLib from 'pdfjs-dist'
import {
  Flex,
  HStack,
  Text,
  IconButton,
  Token,
  Icon,
  Sticky,
  Relative,
  Spinner,
  Box,
  ProductWidgetSkeleton,
} from '@revolut/ui-kit'

import { formatPercentage } from '@src/utils/format'
import {
  PdfPreviewPagination,
  PdfPreviewScale,
  usePagination,
  useRenderPdf,
  useScale,
} from './hooks'

pdfJsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

const ToolbarWrapper: React.FC<{
  borderRadius: string
  stickyOffset?: number
}> = ({ borderRadius, stickyOffset, children }) => {
  const commonProps = {
    width: '100%',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    style: {
      boxShadow: `0px 5px 10px ${Token.color.foreground_10}`,
      zIndex: 10,
    },
  }

  return stickyOffset ? (
    <Sticky left={0} top={stickyOffset} {...commonProps}>
      {children}
    </Sticky>
  ) : (
    <Box {...commonProps}>{children}</Box>
  )
}

type PdfPreviewToolbarProps = {
  scale: PdfPreviewScale
  page: PdfPreviewPagination
  borderRadius: string
  stickyOffset?: number
}
export const PdfPreviewToolbar = ({
  scale,
  page,
  borderRadius,
  stickyOffset,
}: PdfPreviewToolbarProps) => {
  return (
    <ToolbarWrapper stickyOffset={stickyOffset} borderRadius={borderRadius}>
      <Flex
        width="100%"
        p="s-20"
        justifyContent="space-between"
        bg={Token.color.background}
        borderTopLeftRadius={borderRadius}
        borderTopRightRadius={borderRadius}
      >
        {page && (
          <HStack align="center" space="s-12">
            <IconButton
              size={15}
              useIcon="ChevronLeft"
              disabled={page.prevDisabled}
              onClick={page.prev}
              color={page.prevDisabled ? Token.color.greyTone50 : Token.color.blue}
            />
            <Text size="sm" color={Token.color.greyTone50}>
              Page {page.current} of {page.total}
            </Text>
            <IconButton
              size={15}
              useIcon="ChevronRight"
              disabled={page.nextDisabled}
              onClick={page.next}
              color={page.nextDisabled ? Token.color.greyTone50 : Token.color.blue}
            />
          </HStack>
        )}
        <HStack align="center" space="s-12">
          <IconButton
            size={15}
            useIcon="Minus"
            disabled={scale.downDisabled}
            onClick={scale.down}
            color={scale.downDisabled ? Token.color.greyTone50 : Token.color.blue}
          />
          <HStack align="center" space="s-4">
            <Icon size={15} name="Search" color={Token.color.greyTone50} />
            <Text size="sm" color={Token.color.greyTone50}>
              {formatPercentage(scale.value)}
            </Text>
          </HStack>
          <IconButton
            size={15}
            useIcon="Plus"
            disabled={scale.upDisabled}
            onClick={scale.up}
            color={scale.upDisabled ? Token.color.greyTone50 : Token.color.blue}
          />
        </HStack>
      </Flex>
    </ToolbarWrapper>
  )
}

const DocumentLoader = ({ height }: { height?: number }) => (
  <Flex
    flexDirection="column"
    height={height}
    maxWidth="100%"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner size={32} color={Token.color.blue} />
  </Flex>
)

type Props = {
  fileUrl: string | undefined
  containerRef: React.RefObject<HTMLDivElement>
  renderPageOverlay?: (scale: PdfPreviewScale, pageNum?: number) => React.ReactNode
  toolbarStickyOffset?: number
  adjustByParentWidth?: boolean
}
export const PdfPreview = ({
  fileUrl,
  containerRef,
  renderPageOverlay,
  toolbarStickyOffset,
  adjustByParentWidth,
}: Props) => {
  const [pageHeight, setPageHeight] = useState<number>()

  const borderRadius = Token.radius.r12
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const scale = useScale({})
  const page = usePagination({})
  const pdfRenderCtx = useRenderPdf({
    url: fileUrl,
    canvasRef,
    scale: scale.value,
    width: containerRef.current?.offsetWidth,
    page: page.current,
    onMount: pdfDoc => {
      page.setTotal(pdfDoc.numPages)
    },
    onPageRendered: renderCtx => {
      setPageHeight(renderCtx.viewport.height)
    },
    adjustByParentWidth,
  })

  const isMounted = fileUrl && pdfRenderCtx.isMounted
  const showDocLoader = !isMounted || pdfRenderCtx.isLoading
  const displayDocument = showDocLoader ? 'none' : undefined
  const loader = showDocLoader ? <DocumentLoader height={pageHeight} /> : null

  return (
    <Flex
      flexDirection="column"
      maxWidth="100%"
      ref={containerRef}
      bg={Token.color.greyTone8}
      borderRadius={borderRadius}
      pb={isMounted ? 's-12' : undefined}
    >
      {isMounted && (
        <PdfPreviewToolbar
          scale={scale}
          page={page}
          stickyOffset={toolbarStickyOffset}
          borderRadius={borderRadius}
        />
      )}
      {isMounted ? loader : <ProductWidgetSkeleton />}
      <Relative display={displayDocument} overflow="scroll">
        {renderPageOverlay?.(scale, page.current)}
        <canvas ref={canvasRef} />
      </Relative>
    </Flex>
  )
}
