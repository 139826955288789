import React, { useMemo } from 'react'
import { Box, ErrorWidget, Group, Item, ItemSkeleton } from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import { format } from 'date-fns'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import EmailThreadItemAvatar from '@src/features/EmailThread/EmailThreadItemAvatar'
import EmailContent from '@src/pages/Forms/Candidate/Emails/EmailContent'
import AddBubbles from '@src/assets/3D/AddBubbles@2x.png'
import SettingsButtons from '@src/features/SettingsButtons'
import SendEmail from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/SendEmail'
import SyncEmails from '@src/pages/Forms/Candidate/Emails/SyncEmails'
import HideIfGmailDisabled from '@components/HideIfGmailDisabled/HideIfGmailDisabled'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { useGetCandidateEmailThreads } from '@src/api/hiringProcess'

interface EmailsInterface {
  candidateId?: number
}

const Emails = ({ candidateId }: EmailsInterface) => {
  const { data: emailData, isLoading: loadingEmails } =
    useGetCandidateEmailThreads(candidateId)

  const { setActiveAction } = useCandidateProfileContext()
  const threads = useMemo(
    () => emailData?.results?.filter(thread => thread.candidate_emails.length > 0),
    [emailData],
  )
  return (
    <>
      <PageBody>
        <SettingsButtons pb="s-16">
          <SendEmail
            onClick={() => {
              setActiveAction({ type: 'sendEmail' })
            }}
          />
          {candidateId && (
            <HideIfGmailDisabled>
              <SyncEmails id={candidateId} />
            </HideIfGmailDisabled>
          )}
        </SettingsButtons>
        <Box>
          {loadingEmails && <ItemSkeleton />}
          {!loadingEmails && !threads?.length && (
            <ErrorWidget>
              <ErrorWidget.Image src={AddBubbles} />
              <ErrorWidget.Title>No email communication found</ErrorWidget.Title>
            </ErrorWidget>
          )}
          <Group>
            {threads?.map(thread => (
              <Item
                use="button"
                key={thread.id}
                onClick={() => {
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.SEND_EMAIL.VIEW, {
                      candidateId,
                      threadId: thread.id,
                      emailId: thread.candidate_emails[0].id,
                    }),
                  )
                }}
              >
                <EmailThreadItemAvatar email={thread.candidate_emails[0]} />
                <EmailContent thread={thread} />
                <Item.Side>
                  <Item.Value variant="secondary">
                    {format(new Date(thread.latest_sent_date_time), 'd MMM, hh:mm aaa')}
                  </Item.Value>
                </Item.Side>
              </Item>
            ))}
          </Group>
        </Box>
      </PageBody>
    </>
  )
}

export default Emails
