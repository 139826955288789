import React from 'react'
import { Cell, Radio, RadioGroup, Flex, Box } from '@revolut/ui-kit'
import { Grid } from '@components/CommonSC/Grid'
import { useLapeField } from '@src/features/Form/LapeForm'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { connect } from 'lape'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import { isEqual } from 'lodash'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'

type RadioSwitchVariant = 'horizontal'

type RadioSwitchProps<T> = {
  disabled?: boolean
  options: {
    id: string | number
    label: React.ReactNode
    description?: React.ReactNode
    value: T
  }[]
  value: T | undefined
  variant?: RadioSwitchVariant
  onChange: (value: T) => void
  highlightSelected?: boolean
}

const RadioSwitch = <T,>({
  disabled,
  options,
  value,
  variant,
  onChange,
  highlightSelected = false,
}: RadioSwitchProps<T>) => {
  const handleChange = (newValue: T | null) => {
    if (newValue !== null) {
      onChange(newValue)
    }
  }
  return (
    <RadioGroup<T> value={value} onChange={handleChange}>
      {group => (
        <Grid
          flow={variant === 'horizontal' ? 'column' : 'row'}
          gap={16}
          css={{ gridAutoColumns: 'minmax(0, 1fr)' }}
        >
          {options.map(o => (
            <Cell
              use="label"
              key={o.id}
              height="100%"
              aria-pressed={highlightSelected && isEqual(o.value, value)}
            >
              <Flex alignSelf="start">
                <Radio
                  {...group.getInputProps({ value: o.value })}
                  align="start"
                  disabled={disabled}
                  checked={isEqual(o.value, value)}
                >
                  <Radio.Label>{o.label}</Radio.Label>
                  {o.description && (
                    <Radio.Description>{o.description}</Radio.Description>
                  )}
                </Radio>
              </Flex>
            </Cell>
          ))}
        </Grid>
      )}
    </RadioGroup>
  )
}

export default RadioSwitch

export type RecommendedLabelType = 'yes' | 'no'

type BooleanRadioSwitchProps = Omit<RadioSwitchProps<boolean>, 'options'> & {
  yesLabel?: React.ReactNode
  yesDescription?: React.ReactNode
  noLabel?: React.ReactNode
  noDescription?: React.ReactNode
  recommendedLabel?: RecommendedLabelType
}

export const BooleanRadioSwitch = ({
  yesLabel = 'Yes',
  yesDescription = '',
  noLabel = 'No',
  recommendedLabel,
  noDescription = '',
  ...radioSwitchProps
}: BooleanRadioSwitchProps) => {
  return (
    <RadioSwitch<boolean>
      {...radioSwitchProps}
      options={[
        {
          id: 'yes',
          label:
            recommendedLabel === 'yes' ? (
              <>
                {yesLabel} <Recommended />
              </>
            ) : (
              yesLabel
            ),
          description: yesDescription,
          value: true,
        },
        {
          id: 'no',
          label:
            recommendedLabel === 'no' ? (
              <>
                {noLabel} <Recommended />
              </>
            ) : (
              noLabel
            ),
          description: noDescription,
          value: false,
        },
      ]}
    />
  )
}

export type LapeBooleanRadioSwitchProps = Omit<
  BooleanRadioSwitchProps,
  'options' | 'onChange' | 'value'
> & {
  name: string
  onAfterChange?: (value: boolean) => void
}

export const LapeBooleanRadioSwitch = connect(
  ({ name, onAfterChange, ...radioSwitchProps }: LapeBooleanRadioSwitchProps) => {
    const lapeProps = useLapeField(name)
    const { value, onChange, touched, error } = lapeProps
    const formValidator = useFormValidator()

    const commonInputProps = getCommonInputProps(
      { ...radioSwitchProps, name },
      lapeProps,
      !!formValidator?.validated,
    )

    const hasError = (touched && error) || commonInputProps['aria-invalid']

    return (
      <Box data-name={name}>
        <BooleanRadioSwitch
          {...radioSwitchProps}
          value={value}
          onChange={(newValue: boolean) => {
            onChange(newValue)
            onAfterChange?.(newValue)
          }}
        />
        {hasError && commonInputProps.message ? (
          <InputErrorMessage message={commonInputProps.message} />
        ) : undefined}
      </Box>
    )
  },
)

type LapeRadioSwitchProps<T> = Omit<RadioSwitchProps<T>, 'onChange' | 'value'> & {
  name: string
}

const LapeRadioSwitchInner = <T,>({
  name,
  ...radioSwitchProps
}: LapeRadioSwitchProps<T>) => {
  const lapeProps = useLapeField(name)
  const { value, onChange, touched, error } = lapeProps
  const formValidator = useFormValidator()

  const commonInputProps = getCommonInputProps(
    { ...radioSwitchProps, name },
    lapeProps,
    !!formValidator?.validated,
  )

  const hasError = (touched && error) || commonInputProps['aria-invalid']

  return (
    <Box data-name={name}>
      <RadioSwitch<T>
        highlightSelected
        {...radioSwitchProps}
        value={value}
        onChange={onChange}
      />
      {hasError && commonInputProps.message ? (
        <InputErrorMessage message={commonInputProps.message} />
      ) : undefined}
    </Box>
  )
}

export const LapeRadioSwitch = connect(LapeRadioSwitchInner)
