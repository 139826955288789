import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { Organisation } from '@src/pages/Settings/Organisation/Organisation'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { CustomFields } from '@src/pages/Settings/Organisation/CustomFields'

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.ORGANISATION.GENERAL,
    url: ROUTES.SETTINGS.ORGANISATION.GENERAL,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: Organisation,
  },
  {
    title: 'Custom fields',
    path: ROUTES.SETTINGS.ORGANISATION.CUSTOM_FIELDS,
    url: ROUTES.SETTINGS.ORGANISATION.CUSTOM_FIELDS,
    canView: [PermissionTypes.ViewCustomField],
    component: CustomFields,
  },
]

export const OrganisationSettings = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Teams app settings"
      subtitle="All settings for team, department and company profiles"
    />
  )
}
