import cronstrue from 'cronstrue'

import {
  NotificationTemplateInterface,
  NotificationAction,
} from '@src/interfaces/notificationTemplate'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export const getScheduleMessage = (values: NotificationTemplateInterface) => {
  if (!values.schedule) {
    return null
  }

  let message = ''

  try {
    message = cronstrue.toString(values.schedule)
  } catch {
    message = 'Invalid cron value'
  }

  return message
}

export const actionOptions: RadioSelectOption<{
  id: NotificationAction
  name: string
}>[] = [
  {
    label: 'Navigate to URL',
    value: { id: NotificationAction.url, name: 'Navigate to URL' },
  },
  {
    label: 'Show full text',
    value: { id: NotificationAction.text, name: 'Show full text' },
  },
]
