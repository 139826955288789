import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageWrapper } from '@src/components/Page/Page'
import { CustomFields } from '@src/pages/Settings/Benefits/CustomFields'
import { PageHeader } from '@components/Page/Header/PageHeader'

export const BenefitsSettings = () => {
  useSetDocumentTitle('Settings', 'Benefits')

  return (
    <PageWrapper>
      <PageHeader
        title="Benefits settings"
        subtitle="Custom fields"
        backUrl={ROUTES.SETTINGS.ALL}
      />
      <Switch>
        <Route path={ROUTES.SETTINGS.BENEFITS.CUSTOM_FIELDS} exact>
          <CustomFields />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
