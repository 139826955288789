import React, { MouseEvent, useState } from 'react'
import {
  Action,
  Avatar,
  Box,
  Button,
  Ellipsis,
  Flex,
  Group,
  Item,
  TransitionCollapse,
} from '@revolut/ui-kit'
import { Document } from '@revolut/icons'
import DOMPurify from 'dompurify'
import { format } from 'date-fns'

import { FileInterface } from '@src/interfaces/files'
import EmailThreadItemAvatar from '@src/features/EmailThread/EmailThreadItemAvatar'
import { getSenderDetails } from '@src/features/EmailThread/utils'
import { SendEmployeeEmailInterface } from '@src/interfaces/employeeEmails'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface EmailThreadItemProps {
  replyUrl?: string
  email: SendEmployeeEmailInterface | SendCandidateEmailInterface
  isFirst?: boolean
  onShowFilePreview?: (params: { id: number; name: string; url: string | null }) => void
}

const EmailThreadItem = ({
  email,
  replyUrl,
  isFirst = false,
  onShowFilePreview,
}: EmailThreadItemProps) => {
  const [expanded, setExpanded] = useState(isFirst)

  const onExpand = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  const direction = email.direction

  const sender = getSenderDetails({
    ...email,
    direction: typeof direction === 'string' ? direction : direction.id,
  })
  const recipient = email.recipient_name || email.recipient_email

  return (
    <Box mb={expanded ? 's-16' : 0} mt={expanded && !isFirst ? 's-16' : 0}>
      <Item use="button" onClick={onExpand}>
        <EmailThreadItemAvatar email={email} />
        <Item.Content>
          <Item.Title>{`From: ${
            sender.name || sender.email
          }, To: ${recipient}`}</Item.Title>
          <Item.Description>
            {!!replyUrl && expanded && (
              <Flex>
                <Action use={InternalLink} to={replyUrl}>
                  Reply
                </Action>
              </Flex>
            )}
            {!expanded && <Ellipsis>{email.snippet}</Ellipsis>}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <Item.Value variant="secondary">
            {format(new Date(email.creation_date_time), 'd MMM, hh:mm aaa')}
          </Item.Value>
        </Item.Side>
      </Item>
      <TransitionCollapse in={expanded} duration={200}>
        <>
          <Box
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(email.email_body),
            }}
            p="s-16"
          />
          <Group>
            {(email.attachments as FileInterface[])?.map(attachment => (
              <Item key={`attachment_${attachment.id}`}>
                <Item.Avatar>
                  <Avatar useIcon={Document} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{attachment.name}</Item.Title>
                </Item.Content>
                {attachment.id && (
                  <Item.Side>
                    <Button
                      onClick={() =>
                        onShowFilePreview?.({
                          id: attachment.id,
                          url: attachment.url,
                          name: attachment.name,
                        })
                      }
                      useIcon={Document}
                      variant="secondary"
                      size="sm"
                    />
                  </Item.Side>
                )}
              </Item>
            ))}
          </Group>
        </>
      </TransitionCollapse>
    </Box>
  )
}

export default EmailThreadItem
