import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { Function } from '@src/pages/Settings/Function/Function'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { CustomFields } from '@src/pages/Settings/Function/CustomFields'

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.ROLES.GENERAL,
    url: ROUTES.SETTINGS.ROLES.GENERAL,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: Function,
  },
  {
    title: 'Custom fields',
    path: ROUTES.SETTINGS.ROLES.CUSTOM_FIELDS,
    url: ROUTES.SETTINGS.ROLES.CUSTOM_FIELDS,
    canView: [PermissionTypes.ViewCustomField],
    component: CustomFields,
  },
]

export const FunctionSettings = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Roles app settings"
      subtitle="All settings for functions, roles and specialisations"
    />
  )
}
