import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { requisitionSettings } from '@src/api/settings'
import RequisitionsGeneral from '@src/pages/Settings/Requisitions/General'
import { CustomFields } from '@src/pages/Settings/Requisitions/CustomFields'

const routes = [
  {
    title: 'Requisitions settings',
    description: 'Settings related to requisitions setup & management',
    path: ROUTES.SETTINGS.REQUISITIONS.GENERAL,
    url: ROUTES.SETTINGS.REQUISITIONS.GENERAL,
    canView: [
      PermissionTypes.ViewRequisitionPreferences,
      PermissionTypes.ChangeRequisitionPreferences,
    ],
    component: RequisitionsGeneral,
  },
  {
    title: 'Custom fields',
    path: ROUTES.SETTINGS.REQUISITIONS.CUSTOM_FIELDS,
    url: ROUTES.SETTINGS.REQUISITIONS.CUSTOM_FIELDS,
    canView: [PermissionTypes.ViewCustomField],
    component: CustomFields,
  },
]

export const RequisitionsSettings = () => {
  return <SettingsForm routes={routes} api={requisitionSettings} />
}
